import { AdjustmentTypePolicy } from "constants/charge/AdjustmentTypePolicy";
import { ChargeConstantCollection } from "constants/charge/ChargeConstantCollection";
import { AdjustmentTypeDroOpts } from "presentation/constant/DropDownOptions/Charge/AdjustmentTypeDroOpts";
import { ActiveIndDroOpts } from "presentation/constant/DropDownOptions/Common/ActiveIndDroOpts";
import { collectionTypeDropdownOption, correctionDropdownOption, docTypeDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { InternalCorrectionMaintenanceConstant } from "presentation/constant/InternalCorrection/InternalCorrectionMaintenanceConstant";
import { useInternalCorrectionMaintenanceVM } from "presentation/hook/InternalCorrection/useInternalCorrectionMaintenanceVM";
import { useInternalCorrectionMaintenanceTracked } from "presentation/store/InternalCorrection/InternalCorrectionMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { useCallback, useEffect, useMemo } from "react";
import { FieldType, HPHCheckbox, IFieldValue } from "veronica-ui-component/dist/component/core";
import { SidebarCaption, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import InternalCorrectionEditPanelTitleBar from "./InternalCorrectionEditPanelTitleBar";

export const InternalCorrectionMaintenanceFormPanel = () => {
    const [internalCorrectionState] = useInternalCorrectionMaintenanceTracked();
    const internalCorrectionVM = useInternalCorrectionMaintenanceVM();
    const { currentSelectedRow, masterState, dynamicOptions } = internalCorrectionState;
    const { editingEntity, isAdd, isEditable, isRead, isSaveClicked, allFormState } = masterState;
    const SCREEN_CONSTANT = InternalCorrectionMaintenanceConstant;


    const currentEntity = isRead ? currentSelectedRow : editingEntity;


    const memoSubChgTypeOptions = useMemo(() => {
        const subChgTypeOptions = currentEntity.chargeType ? dynamicOptions.subChargeTypeDropdownOptions[currentEntity.chargeType] : [];
        return subChgTypeOptions;
    }, [currentEntity.chargeType, dynamicOptions.subChargeTypeDropdownOptions]);

    const memoTarCodeOptions = useMemo(() => {
        const tarCodeOptions = currentEntity.tariffType ? dynamicOptions.tariffCodeDropdownOptions[currentEntity.tariffType] : [];
        return tarCodeOptions;
    }, [currentEntity.tariffType, dynamicOptions.tariffCodeDropdownOptions]);


    const onNumberFieldChange = useCallback((e: any, fieldName: string, fieldValue: any) => {
        internalCorrectionVM.onFieldChange(fieldName, fieldValue);
    }, [internalCorrectionVM]);

    useEffect(() => {
        internalCorrectionVM.onCorrectionChanged();
    }, [currentEntity.correction, internalCorrectionVM])

    const memoTariffType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd)}
                isShowOptional={true}
                readOnlyValue={currentEntity?.tariffType || ''}
                fieldValue={currentEntity?.tariffType}
                fieldLabel={SCREEN_CONSTANT.TARIFF_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffType'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.tariffTypeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    internalCorrectionVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.tariffType, SCREEN_CONSTANT.TARIFF_TYPE, isSaveClicked, dynamicOptions.tariffTypeDropdownOptions, internalCorrectionVM])

    const memoTariffCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd)}
                isShowOptional={true}
                readOnlyValue={currentEntity?.tariffCode || ''}
                fieldValue={currentEntity?.tariffCode}
                fieldLabel={SCREEN_CONSTANT.TARIFF_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffCode'}
                isShowMissingError={true}
                maxLength={10}
                options={memoTarCodeOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    internalCorrectionVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.tariffCode, SCREEN_CONSTANT.TARIFF_CODE, isSaveClicked, memoTarCodeOptions, internalCorrectionVM])

    const memoDocType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd)}
                isShowOptional={true}
                readOnlyValue={currentEntity?.docType || ''}
                fieldValue={currentEntity?.docType}
                fieldLabel={SCREEN_CONSTANT.DOC_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'docType'}
                isShowMissingError={true}
                maxLength={10}
                options={docTypeDropdownOption}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    internalCorrectionVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.docType, SCREEN_CONSTANT.DOC_TYPE, isSaveClicked, internalCorrectionVM])

    const memoAdjType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd)}
                isShowOptional={true}
                readOnlyValue={currentEntity?.adjType || ''}
                fieldValue={currentEntity?.adjType}
                fieldLabel={SCREEN_CONSTANT.ADJ_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'adjType'}
                isShowMissingError={true}
                maxLength={10}
                options={AdjustmentTypeDroOpts().getAdjustmentTypeModel()?.filter(e => e.value !== AdjustmentTypePolicy.TEXT_RECAL)}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    internalCorrectionVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.adjType, SCREEN_CONSTANT.ADJ_TYPE, isSaveClicked, internalCorrectionVM])

    const memoChargeOnCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd)}
                isShowOptional={true}
                readOnlyValue={currentEntity?.chargeOnCompany || ''}
                fieldValue={currentEntity?.chargeOnCompany}
                fieldLabel={SCREEN_CONSTANT.CHARGE_ON_COMPANY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeOnCompany'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.chargeOnCompanyDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    internalCorrectionVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.chargeOnCompany, SCREEN_CONSTANT.CHARGE_ON_COMPANY, isSaveClicked, dynamicOptions.chargeOnCompanyDropdownOptions, internalCorrectionVM])

    const memoBillToCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd)}
                isShowOptional={true}
                readOnlyValue={currentEntity?.billToCompany || ''}
                fieldValue={currentEntity?.billToCompany}
                fieldLabel={SCREEN_CONSTANT.BILL_TO_COMPANY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'billToCompany'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.billToCompanyDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    internalCorrectionVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.billToCompany, SCREEN_CONSTANT.BILL_TO_COMPANY, isSaveClicked, dynamicOptions.billToCompanyDropdownOptions, internalCorrectionVM])

    const memoChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd)}
                isShowOptional={true}
                readOnlyValue={currentEntity?.chargeType || ''}
                fieldValue={currentEntity?.chargeType}
                fieldLabel={SCREEN_CONSTANT.CHARGE_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeType'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.chargeTypeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    internalCorrectionVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.chargeType, SCREEN_CONSTANT.CHARGE_TYPE, isSaveClicked, dynamicOptions.chargeTypeDropdownOptions, internalCorrectionVM])

    const memoSubChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd)}
                isShowOptional={true}
                readOnlyValue={currentEntity?.subChargeType || ''}
                fieldValue={currentEntity?.subChargeType}
                fieldLabel={SCREEN_CONSTANT.SUB_CHARGE_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'subChargeType'}
                isShowMissingError={true}
                maxLength={10}
                options={memoSubChgTypeOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    internalCorrectionVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.subChargeType, SCREEN_CONSTANT.SUB_CHARGE_TYPE, isSaveClicked, memoSubChgTypeOptions, internalCorrectionVM])

    const memoModalityOfArrival = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd)}
                isShowOptional={true}
                readOnlyValue={currentEntity?.modalityOfArrival || ''}
                fieldValue={currentEntity?.modalityOfArrival}
                fieldLabel={SCREEN_CONSTANT.MODALITY_OF_ARRIVAL}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'modalityOfArrival'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.modalityDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    internalCorrectionVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.modalityOfArrival, SCREEN_CONSTANT.MODALITY_OF_ARRIVAL, isSaveClicked, dynamicOptions.modalityDropdownOptions, internalCorrectionVM])

    const memoModalityOfDeparture = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd)}
                isShowOptional={true}
                readOnlyValue={currentEntity?.modalityOfDeparture || ''}
                fieldValue={currentEntity?.modalityOfDeparture}
                fieldLabel={SCREEN_CONSTANT.MODALITY_OF_DEPARTURE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'modalityOfDeparture'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.modalityDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    internalCorrectionVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.modalityOfDeparture, SCREEN_CONSTANT.MODALITY_OF_DEPARTURE, isSaveClicked, dynamicOptions.modalityDropdownOptions, internalCorrectionVM])

    const memoContainerStatus = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd)}
                isShowOptional={true}
                readOnlyValue={currentEntity?.cntrStatus || ''}
                fieldValue={currentEntity?.cntrStatus}
                fieldLabel={SCREEN_CONSTANT.CONTAINER_STATUS}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cntrStatus'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.cntrStatusDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    internalCorrectionVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.cntrStatus, SCREEN_CONSTANT.CONTAINER_STATUS, isSaveClicked, dynamicOptions.cntrStatusDropdownOptions, internalCorrectionVM])

    const memoRecal = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd)}
                isShowOptional={true}
                readOnlyValue={currentEntity?.recalInd || ''}
                fieldValue={currentEntity?.recalInd}
                fieldLabel={SCREEN_CONSTANT.RECAL}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'recalInd'}
                isShowMissingError={true}
                maxLength={10}
                options={ActiveIndDroOpts().getDroOptsModel()}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    internalCorrectionVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.recalInd, SCREEN_CONSTANT.RECAL, isSaveClicked, internalCorrectionVM])

    const memoRevenueCompanyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd)}
                isShowOptional={false}
                readOnlyValue={currentEntity?.revenueCompanyCode || ''}
                fieldValue={currentEntity?.revenueCompanyCode}
                fieldLabel={SCREEN_CONSTANT.REVENUE_COMPANY_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'revenueCompanyCode'}
                isShowMissingError={true}
                maxLength={10}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    internalCorrectionVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.revenueCompanyCode, SCREEN_CONSTANT.REVENUE_COMPANY_CODE, isSaveClicked, internalCorrectionVM])

    const memoRevenueAccountCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd)}
                isShowOptional={false}
                readOnlyValue={currentEntity?.revenueAccountCode || ''}
                fieldValue={currentEntity?.revenueAccountCode}
                fieldLabel={SCREEN_CONSTANT.REVENUE_AC_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'revenueAccountCode'}
                isShowMissingError={true}
                maxLength={10}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    internalCorrectionVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.revenueAccountCode, SCREEN_CONSTANT.REVENUE_AC_CODE, isSaveClicked, internalCorrectionVM])

    const memoCostCenterCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd)}
                isShowOptional={false}
                readOnlyValue={currentEntity?.costCenterCode || ''}
                fieldValue={currentEntity?.costCenterCode}
                fieldLabel={SCREEN_CONSTANT.COST_CENTER_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'costCenterCode'}
                isShowMissingError={true}
                maxLength={10}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    internalCorrectionVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.costCenterCode, SCREEN_CONSTANT.COST_CENTER_CODE, isSaveClicked, internalCorrectionVM])

    const memoProductServiceCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd)}
                isShowOptional={false}
                readOnlyValue={currentEntity?.productServiceCode || ''}
                fieldValue={currentEntity?.productServiceCode}
                fieldLabel={SCREEN_CONSTANT.PRODUCT_SERVICE_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'productServiceCode'}
                isShowMissingError={true}
                maxLength={10}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    internalCorrectionVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.productServiceCode, SCREEN_CONSTANT.PRODUCT_SERVICE_CODE, isSaveClicked, internalCorrectionVM])

    const memoSalesChannelCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd)}
                isShowOptional={false}
                readOnlyValue={currentEntity?.salesChannelCode || ''}
                fieldValue={currentEntity?.salesChannelCode}
                fieldLabel={SCREEN_CONSTANT.SALES_CHANNEL_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'salesChannelCode'}
                isShowMissingError={true}
                maxLength={10}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    internalCorrectionVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.salesChannelCode, SCREEN_CONSTANT.SALES_CHANNEL_CODE, isSaveClicked, internalCorrectionVM])

    const memoCountryCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd)}
                isShowOptional={false}
                readOnlyValue={currentEntity?.countryCode || ''}
                fieldValue={currentEntity?.countryCode}
                fieldLabel={SCREEN_CONSTANT.COUNTRY_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'countryCode'}
                isShowMissingError={true}
                maxLength={10}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    internalCorrectionVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.countryCode, SCREEN_CONSTANT.COUNTRY_CODE, isSaveClicked, internalCorrectionVM])

    const memoIntercompanyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd)}
                isShowOptional={false}
                readOnlyValue={currentEntity?.intercompanyCode || ''}
                fieldValue={currentEntity?.intercompanyCode}
                fieldLabel={SCREEN_CONSTANT.INTERCOMPANY_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'intercompanyCode'}
                isShowMissingError={true}
                maxLength={10}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    internalCorrectionVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.intercompanyCode, SCREEN_CONSTANT.INTERCOMPANY_CODE, isSaveClicked, internalCorrectionVM])

    const memoProjectCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd)}
                isShowOptional={false}
                readOnlyValue={currentEntity?.projectCode || ''}
                fieldValue={currentEntity?.projectCode}
                fieldLabel={SCREEN_CONSTANT.PROJECT_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'projectCode'}
                isShowMissingError={true}
                maxLength={10}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    internalCorrectionVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.projectCode, SCREEN_CONSTANT.PROJECT_CODE, isSaveClicked, internalCorrectionVM])

    const memoSpareCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd)}
                isShowOptional={false}
                readOnlyValue={currentEntity?.spareCode || ''}
                fieldValue={currentEntity?.spareCode}
                fieldLabel={SCREEN_CONSTANT.SPARE_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'spareCode'}
                isShowMissingError={true}
                maxLength={10}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    internalCorrectionVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.spareCode, SCREEN_CONSTANT.SPARE_CODE, isSaveClicked, internalCorrectionVM])

    const memoCorrection = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd)}
                isShowOptional={false}
                readOnlyValue={currentEntity?.correction || ''}
                fieldValue={currentEntity?.correction}
                fieldLabel={SCREEN_CONSTANT.CORRECTION}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'correction'}
                isShowMissingError={true}
                maxLength={10}
                options={correctionDropdownOption}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    internalCorrectionVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.correction, SCREEN_CONSTANT.CORRECTION, isSaveClicked, internalCorrectionVM])

    const memoCorrectionType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd) || ChargeConstantCollection.correction.FIX === currentEntity?.correction}
                isShowOptional={false}
                readOnlyValue={currentEntity?.correctionType || ''}
                fieldValue={currentEntity?.correctionType}
                fieldLabel={SCREEN_CONSTANT.CORRECTION_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'correctionType'}
                isShowMissingError={true}
                maxLength={10}
                options={collectionTypeDropdownOption}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    internalCorrectionVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.correction, currentEntity?.correctionType, SCREEN_CONSTANT.CORRECTION_TYPE, isSaveClicked, internalCorrectionVM])

    const memoCorrectionAmount = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "230px", marginBottom: "24px" }}>
            {/* <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || ChargeConstantCollection.correction.DISCHARGE === currentEntity?.correction}
                isShowOptional={false}
                readOnlyValue={currentEntity?.correctionAmount || ''}
                fieldValue={currentEntity?.correctionAmount}
                fieldLabel={SCREEN_CONSTANT.CORRECTION_AMOUNT}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'correctionAmount'}
                isShowMissingError={true}
                maxLength={10}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    internalCorrectionVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} /> */}

            <NumberInputComponent
                label={SCREEN_CONSTANT.CORRECTION_AMOUNT}
                maxLength={60}
                fieldName={"correctionAmount"}
                value={currentEntity?.correctionAmount ?? ''}
                errorMessage={allFormState?.correctionAmount}
                disabled={!(isEditable || isAdd) || ChargeConstantCollection.correction.DISCHARGE === currentEntity?.correction}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [SCREEN_CONSTANT.CORRECTION_AMOUNT, currentEntity?.correctionAmount, currentEntity?.correction, allFormState?.correctionAmount, isEditable, isAdd, onNumberFieldChange])

    const memoRemarkForDocument = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "750px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd)}
                isShowOptional={true}
                readOnlyValue={currentEntity?.remarkForDocument || ''}
                fieldValue={currentEntity?.remarkForDocument}
                fieldLabel={SCREEN_CONSTANT.REMARK_FOR_DOCUMENT}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'remarkForDocument'}
                isShowMissingError={true}
                maxLength={300}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    internalCorrectionVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.remarkForDocument, SCREEN_CONSTANT.REMARK_FOR_DOCUMENT, isSaveClicked, internalCorrectionVM])

    const memoRemarkForEstimation = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "750px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!(isEditable || isAdd)}
                isShowOptional={true}
                readOnlyValue={currentEntity?.remarkForEstimation || ''}
                fieldValue={currentEntity?.remarkForEstimation}
                fieldLabel={SCREEN_CONSTANT.REMARK_FOR_ESTIMATION}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'remarkForEstimation'}
                isShowMissingError={true}
                maxLength={300}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    internalCorrectionVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, isAdd, currentEntity?.remarkForEstimation, SCREEN_CONSTANT.REMARK_FOR_ESTIMATION, isSaveClicked, internalCorrectionVM])

    const memoActiveInd = useMemo(() =>
        <>
            <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
                <NbisCommonField
                    fieldKey={"activeInd"}
                    requiredFieldList={[]}
                    fieldLabel={SCREEN_CONSTANT.ACTIVE_IND}
                    fieldType={FieldType.TEXT}
                    isReadOnly={true}
                    onFieldChange={() => { }} />
                <HPHCheckbox
                    label={""}
                    checked={currentEntity?.activeInd === 'Y'}
                    disabled={!(isEditable || isAdd)}
                    onChange={(e) => internalCorrectionVM.onCheckboxChange(e.checked, "activeInd")}
                />
            </div>
        </>
        , [SCREEN_CONSTANT.ACTIVE_IND, currentEntity?.activeInd, isEditable, isAdd, internalCorrectionVM])


    const memoEditPanelTitleBar = useMemo(() => {
        return <InternalCorrectionEditPanelTitleBar />
    }, [])


    return <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
        {/* Header part of add or edit record */}
        {memoEditPanelTitleBar}

        {/* Readable and Editable Form */}
        <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
            <div className="flex-row-auto">
                <div className={'flex-row-item'}>
                    <div className={'im-flex-row'}>
                        <div className={'flex-row-item-stretch'}>
                            <SidebarTitle>{!isAdd ? currentEntity.tariffType + " / " + currentEntity.tariffCode : "NEW CODE"}</SidebarTitle>

                            {(isAdd || (isEditable && !isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                        </div>
                    </div>

                    <CriteriaItemContainer>
                        {memoTariffType}
                        {memoTariffCode}
                        {memoDocType}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoAdjType}
                        {memoChargeOnCompany}
                        {memoBillToCompany}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoChargeType}
                        {memoSubChargeType}
                        {memoModalityOfArrival}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoModalityOfDeparture}
                        {memoContainerStatus}
                        {memoRecal}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoRevenueCompanyCode}
                        {memoRevenueAccountCode}
                        {memoCostCenterCode}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoProductServiceCode}
                        {memoSalesChannelCode}
                        {memoCountryCode}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoIntercompanyCode}
                        {memoProjectCode}
                        {memoSpareCode}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoCorrection}
                        {memoCorrectionType}
                        {memoCorrectionAmount}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoRemarkForDocument}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoRemarkForEstimation}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoActiveInd}
                    </CriteriaItemContainer>

                </div>
            </div>
        </div>
        {/* Readable and Editable Form */}
    </StyledSidebar>
}
