import { CustomCellRendererProps } from "ag-grid-react";
import { defineColumn } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { InternalCorrectionMaintenanceConstant } from "./InternalCorrectionMaintenanceConstant";

const SCREEN_CONSTANT = InternalCorrectionMaintenanceConstant;
export const INITIAL_INTERNAL_CORRECTION_COL_DEF: any[] = [
    {
        headerName: SCREEN_CONSTANT.TARIFF_TYPE,
        field: "tariffType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
        /*pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,*/
    },
    {
        headerName: SCREEN_CONSTANT.TARIFF_CODE,
        field: "tariffCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.DOC_TYPE,
        field: "docType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.ADJ_TYPE,
        field: "adjType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.CHARGE_ON_COMPANY,
        field: "chargeOnCompany",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.BILL_TO_COMPANY,
        field: "billToCompany",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.CHARGE_TYPE,
        field: "chargeType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.SUB_CHARGE_TYPE,
        field: "subChargeType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.MODALITY_OF_ARRIVAL,
        field: "modalityOfArrival",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.MODALITY_OF_DEPARTURE,
        field: "modalityOfDeparture",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 250,
    },
    {
        headerName: SCREEN_CONSTANT.CONTAINER_STATUS,
        field: "cntrStatus",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.RECAL,
        field: "recalInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.REVENUE_COMPANY_CODE,
        field: "revenueCompanyCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 250,
    },
    {
        headerName: SCREEN_CONSTANT.REVENUE_AC_CODE,
        field: "revenueAccountCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.COST_CENTER_CODE,
        field: "costCenterCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.PRODUCT_SERVICE_CODE,
        field: "productServiceCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 250,
    },
    {
        headerName: SCREEN_CONSTANT.SALES_CHANNEL_CODE,
        field: "salesChannelCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.COUNTRY_CODE,
        field: "countryCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.INTERCOMPANY_CODE,
        field: "intercompanyCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.PROJECT_CODE,
        field: "projectCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.SPARE_CODE,
        field: "spareCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.CORRECTION,
        field: "correction",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.CORRECTION_TYPE,
        field: "correctionType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.CORRECTION_AMOUNT,
        field: "correctionAmount",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.REMARK_FOR_DOCUMENT,
        field: "remarkForDocument",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 250,
    },
    {
        headerName: SCREEN_CONSTANT.REMARK_FOR_ESTIMATION,
        field: "remarkForEstimation",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 250,
    },
    {
        headerName: SCREEN_CONSTANT.ACTIVE_IND,
        field: "activeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
]?.map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, [], [], [], cellRenderers);
});
